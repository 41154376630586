<template>
  <div>
    
    <header class="full-width" :class="currentView.bgClass" :style="'color: '+currentView.textColor">
      <div id="animated-images-container">
        <template v-for="(im, i) in coverImages">
          <img :src="im.src" :class="im.enterClass" :id="`cover-img-${i}`" @load="handleCoverImgLoad(im)"/>
        </template>
      </div>
      <div id="title" class="dgrid-body dgrid-container cover flow">
        <h1>{{ title }}</h1>
        <h4>{{ subtitle }}</h4>
      </div>
    </header>

    <div id="menu" class="text--body1 flow dgrid-container" :class="{'dgrid-body': mobile}">
      <p v-for="(sec, i) in sections" :key="i" @click="goto(sec.ref)">{{sec.menu}}</p>
    </div>

    <div v-if="mobile" class="bordered-bottom"></div>

    <main class="text--body1">
      <section v-for="(sec, i) in sections" :key="i" class="bordered-bottom">

        <div class="dgrid-body dgrid-container flow">

          <h4><div class="anchor" :ref="sec.ref"></div>{{ sec.title }}</h4>
          
          <base-video
            v-if="sec.video"
            :source="sec.video.src"
            :title="sec.video.title"
            :placeholder="sec.video.poster"
          />
          
          <p v-if="!sec.html" class="text--pre">{{ sec.body }}</p>
          <div v-else v-html="sec.html" class="marked"></div>     

        </div>
      </section>
    </main>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import utils from '@js/utils.js';
import * as marked from 'marked';

export default {
  name: 'About',
  props: {
    view: {
      type: String,
      required: true,
      validator: (val) => ['takepart', 'about'].indexOf(val) >= 0
    }
  },
  data() {
    return {
      views: {
        about: {
          title: this.$t('about_titolo_pagina'),
          subtitle: this.$t('about_descrizione_pagina'),
          bgClass: 'bg-primary',
          textColor: 'var(--color-white)',
          sections: [
            {
              menu: this.$t('about_titolo_sez_1'),
              title: this.$t('about_ancora_sez_1'),
              body: this.$t('about_testo_sez_1'),
              ref: 'sec-1',
              video: {
                src: './video/geca_about_video_1.mp4',
                title: this.$t('about_credits_media_sez_1'),
                poster: require('@assets/images/geca_about_video_1_thumb.jpg')
              }
            },
            {
              menu: this.$t('about_titolo_sez_2'),
              title: this.$t('about_ancora_sez_2'),
              body: this.$t('about_testo_sez_2'),
              ref: 'sec-2',
              video: {
                src: './video/geca_about_video_2.mp4',
                title: this.$t('about_credits_media_sez_2'),
                poster: require('@assets/images/geca_about_video_2_thumb.jpg')
              }
            },
            {
              menu: this.$t('about_titolo_sez_3'),
              title: this.$t('about_ancora_sez_3'),
              body: this.$t('about_testo_sez_3'),
              ref: 'sec-3',
              video: {
                src: './video/geca_about_video_3.mp4',
                title: this.$t('about_credits_media_sez_3'),
                poster: require('@assets/images/geca_about_video_3_thumb.jpg')
              }
            },
            {
              menu: this.$t('about_titolo_sez_4'),
              title: this.$t('about_ancora_sez_4'),
              body: this.$t('about_testo_sez_4'),
              ref: 'sec-4'
            }
          ],
          coverImages: [
            {
              src: require('@assets/images/collages/about/GECA_assets_ABOUT_1.png'),
              enterClass: 'enter-left',
              parallaxOrder: 2
            },
            {
              src: require('@assets/images/collages/about/GECA_assets_ABOUT_2.png'),
              enterClass: 'enter-top',
              parallaxOrder: 1
            },
            {
              src: require('@assets/images/collages/about/GECA_assets_ABOUT_3.png'),
              enterClass: 'enter-bottom',
              parallaxOrder: 3
            }
          ]
        },
        takepart: {
          title: this.$t('partecipa_titolo_pagina'),
          subtitle: this.$t('partecipa_descrizione_pagina'),
          bgClass: 'bg-primary-50',
          textColor: 'var(--color-white)',
          sections: [
            {
              menu: this.$t('partecipa_titolo_sez_1'),
              title: this.$t('partecipa_titolo_sez_1'),
              body: this.$t('partecipa_testo_sez_1'),
              ref: 'sec-1'
            },
            {
              menu: this.$t('partecipa_titolo_sez_2'),
              title: this.$t('partecipa_titolo_sez_2'),
              body: this.$t('partecipa_testo_sez_2'),
              ref: 'sec-2'
            },
            {
              menu: this.$t('partecipa_titolo_sez_3'),
              title: this.$t('partecipa_titolo_sez_3'),
              body: this.$t('partecipa_testo_sez_3'),
              html: marked.parse(this.$t('partecipa_testo_sez_3')),
              ref: 'sec-3'
            }
          ],
          coverImages: [
            {
              src: require('@assets/images/collages/partecipa/GECA_assets_PARTECIPA_1.png'),
              enterClass: 'enter-left',
              parallaxOrder: 2
            },
            {
              src: require('@assets/images/collages/partecipa/GECA_assets_PARTECIPA_2.png'),
              enterClass: 'enter-top',
              parallaxOrder: 1
            },
            {
              src: require('@assets/images/collages/partecipa/GECA_assets_PARTECIPA_3.png'),
              enterClass: 'enter-bottom',
              parallaxOrder: 3
            }
          ],
        }
      },
      links: [],
      header: undefined,
      parallaxMult: 3,
      coverImagesEl: []
    }
  },
  computed: {
    ...mapState('interface', ['mobile']),
    currentView() {
      return this.views[this.view];
    },
    sections() {
      return this.currentView.sections;
    },
    title() {
      return this.currentView.title;
    },
    subtitle() {
      return this.currentView.subtitle;
    },
    coverImages() {
      return this.currentView.coverImages;
    }
  },
  watch: {
    $route() {
      this.$nextTick(this.initContrast);
    }
  },
  methods: {
    handleScroll() {
      this.links.forEach(el => {
        let overlapping = utils.elementsFullyOverlap(el, this.header);
        el.classList[overlapping ? 'add' : 'remove']('contrast');
      })

      //get scroll percentage
      var h = document.documentElement, 
      b = document.body,
      st = 'scrollTop',
      sh = 'scrollHeight';
      let scr_perc = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
      
      //animate parallax 
      this.coverImagesEl.forEach((elem, i) => { 
        elem.style.top = -(scr_perc * (this.parallaxMult - this.coverImages[i].parallaxOrder*this.parallaxMult*0.5))+"%";
      })
    },
    goto(ref) {
      var element = this.$refs[ref][0];
      element.scrollIntoView({behavior: 'smooth'});
    },
    initContrast() {
      this.links = this.$el.querySelectorAll('.view #menu > p');
      this.header = this.$el.querySelector('.view header');
      document.addEventListener('scroll', this.handleScroll);
      document.addEventListener('resize', this.handleScroll);
      this.handleScroll();      
    },
    getCoverImgEls() {
      this.coverImages.forEach((im, i) => {
        let el = document.getElementById(`cover-img-${i}`);
        this.coverImagesEl.push(el);
      })
    },
    initEnterAnimation() {
      this.coverImages.forEach((im, i) => {
        let el = this.coverImagesEl[i];
        setTimeout(()=>{
          el.classList.remove(im.enterClass)
        }, i*200);
      })
    },
    handleCoverImgLoad(im) {
      im.loaded = true;
      if (this.coverImages.every(el => el.loaded)) this.initEnterAnimation();
    }
  },
  mounted() {
    this.initContrast();
    this.getCoverImgEls();
    // this.initEnterAnimation();
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('resize', this.handleScroll);
  }
}
</script>

<style lang="scss">
#about.view,
#takepart.view {

  --flow-space: var(--s4);
  position: relative;

  header {
    overflow: hidden;
    position: relative;
    height: calc(100vh - var(--navbar-height));
    // color: var(--color-white);
    .cover {
      align-items: unset;
    }
    #title {
      position: relative;
      z-index: 1;
      height: 100%;
      gap: 0px;
      // background-color:red;

      h1 {
        --dgrid-item-col: 8;
        max-width: var(--dgrid-item-w);
      }
      h4 {
        --dgrid-item-col: 5;
        max-width: var(--dgrid-item-w);
      }

    }
    #animated-images-container {
      position: absolute;
      right: 0px;
      height: 100%;
      max-width: 100%;
      aspect-ratio: 1;

      > img {
        position:absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        mix-blend-mode: multiply;
        transition: all 0.5s ease-out;
        transition-property: opacity, transform;
        opacity: 1;

        &#cover-img-0 {
          transform-origin: 36.1875% 45.125%;
        }

        &.enter-left {
          opacity: 0;
          transform: translateX(-20vw) rotate(-45deg);
           
        }
        &.enter-top {
          opacity: 0;
          transform: translateY(-20vh);
        }
        &.enter-bottom {
          opacity: 0;
          transform: translateY(20vh);
        }
      } 
    }
  }

  #menu {
    --menu-margin: 5.375rem;
    position: fixed;
    width: fit-content;
    top: var(--navbar-height);
    right: var(--menu-margin);
    max-width: calc(var(--menu-margin) + var(--dgrid-item-w)*1.5);
    --flow-space: var(--s2);
    padding: var(--s4) 0px;
    --dgrid-gap: 0px;
    z-index: 2;

    p {
      cursor: pointer;
      transition: all var(--transition-duration) var(--transition-type);
    }
    p:hover {
      transform: translateX(var(--s0));
      color: var(--color-primary);
      text-decoration: underline;
    }
    p.contrast {
      color: var(--color-white);
    }
    p.contrast:hover {
      color: var(--color-light);
    }

    h4 {
      color: var(--color-black-text);
    }
  }

  main > section {
    --flow-space: var(--s4);
    > div {
      padding: var(--s5) calc(var(--dgrid-col-w) + var(--dgrid-gap));
      --dgrid-item-col: 7;
    }
    p, 
    h4 {
      position: relative;
      max-width: var(--dgrid-item-w);
    }

    .video-container {
      width: 80%;
    } 
  }

  .anchor {
      display: block;
      position: absolute;
      top: calc(-2 * var(--navbar-height));
      visibility: hidden;
  }
}


@media (max-width: 720px) {
  #about.view,
  #takepart.view {

    #menu {
      position: static;
      padding: var(--s3) 0px;
      max-width: unset;
    }

    header {
      #title {
        justify-content: flex-start;
        padding-top: var(--s3);
      }
      #animated-images-container {
        height: unset;
        width: 100%;
        bottom: 0px;
      }  
    }


    main > section {
      --flow-space: var(--s3);
      > div {
        padding: var(--s3) 0px;
      }
      p {
        max-width: unset;
      }
    }
  }
}

</style>
